<template>
<div>
	<div v-if="isLoading">
		<CanPayLoader/>
	</div>
  <div class="container" style="height:90vh;">
		<div>
			<!-- Validation modal -->
			<b-modal
				ref="validation-modal"
				hide-footer
				v-b-modal.modal-center
    			no-close-on-backdrop
				modal-backdrop
				hide-header
				id="validation-modal"
				centered
			>
				<div class="color">
					<div class="col-12 text-center">
						<svg
							version="1.1"
							id="Layer_1"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							x="0px"
							y="0px"
							width="120"
							height="120"
							viewBox="0 0 100 125"
							style="enable-background: new 0 0 100 125"
							xml:space="preserve"
							fill="#e14343"
						>
							<path
							d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
					h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
					M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
					c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
					c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
							/>
						</svg>
					</div>
					<div class="purchaserpower-modal-text">
						<div class="d-block text-center">
							<label class="purchasepower-def-label justify-text">
								{{ error_message }}
							</label>
						</div>
						<br />
						<br />
						<div class="text-center">
							<button
								type="button"
								class="mx-auto col-10 offset-1 btn-black"
								style="height: 60px"
								@click="revokeAkoyaToken"
							>
								<label class="purchasepower-modal-ok-label">{{link_success ? 'OK' : 'Try Again'}}</label>
							</button>
						</div>
					</div>
				</div>
			</b-modal>
			<skip-banking-solution-modal ref="SkipBankingSolutionModal"></skip-banking-solution-modal>
		</div>
	</div>
</div>
</template>
<script>
import api from "../../api/registration.js";
import onboarding_api from "../../api/onboarding.js";
import account_api from "../../api/account.js";
import { isMobile } from "mobile-device-detect";
import Success from "../Registration/Success.vue";
import SkipBankingSolutionModal from './SkipBankingSolutionModal.vue';
import Loading from "vue-loading-overlay";
import CanPayLoader from "../CustomLoader/CanPayLoader.vue"
export default {
	name: "Success",
  data() {
    return {
		mobile: false,
      	show: '',
      	sessionID: '',
      	codeValue: '',
      	error_message: '',
      	error_message_generic: 'There was a problem updating your bank details. Please try again. If this issue persists, please report it to CanPay’s customer service team via the live chat option.',
      	error_message_only_checking_or_savings: 'CanPay can only use Checking and Savings accounts for your purchases. Your bank is reporting that you did not share any Checking or Savings accounts. Please try again and make sure at least one Checking or Savings account is selected to share.',
		isLoading: false,
      	fullPage: true,
      	link_success: false,
      	skip_current_banking_solution: false,
      	action_type: 'bank_change',
      	currentUser: {},
    };
  },
	components: {
    success: Success,
    Loading,
	SkipBankingSolutionModal,
	CanPayLoader
  },
	created() {
		if (isMobile) {
			this.mobile = true;
		}
		this.currentUser = localStorage.getItem("consumer_login_response")
		? JSON.parse(localStorage.getItem("consumer_login_response"))
		: null;
		localStorage.setItem("bank_link_success", true);
		setTimeout(function(){
			localStorage.removeItem("bank_link_success");
		}, 10000)
		this.$root.$emit("changeWhiteBackground", [false, true, "common"]);
		this.$root.$emit("show_header", true);
		
		if (localStorage.getItem('consumer_token') != null) {
			this.action_type = 'bank_change';
		} else if (localStorage.getItem('session_data') != null) { // Registration 
			this.sessionID = localStorage.getItem('session_data');
			this.action_type = 'registration';
		} else if (localStorage.getItem('sessionId') != null) { // Onboarding
			this.sessionID = localStorage.getItem('sessionId');
			this.action_type = 'v1 Onboarding';
		}
		// Access the 'code' parameter using $route.query
		this.codeValue = this.$route.query.code;
		if (this.codeValue) {
			this.logAkoyaError(this.$route.query, true);
			if (this.codeValue == localStorage.getItem('akoya_code')) {
				if (localStorage.getItem('consumer_token') != null) {
					this.$router.push("/banklinking");
				} else {
					this.$router.push("/login");
				}
			} else {
				localStorage.setItem("akoya_code", this.codeValue);
				if (localStorage.getItem('consumer_token') != null) {
					this.akoyaBankUpdate();
				} else if (localStorage.getItem('session_data') != null) { // Registration 
					this.registration();
				} else if (localStorage.getItem('sessionId') != null) { // Onboarding
					this.redirectToSuccess();
				}
			}
		} else if (this.$route.query.error) {
			this.logAkoyaError(this.$route.query, false);
			this.skip_current_banking_solution = true;
			setTimeout(() => {
				if (this.$route.query.error_description == 'user canceled') {
					this.error_message = 'No new account was selected. Please select at least one account.';
				} else {
					this.error_message = this.error_message_generic;
				}
				this.showValidationModal(this.error_message);
			}, 100);
		} else {
			this.logAkoyaError(this.$route.query, false);
			this.skip_current_banking_solution = true;
			this.showValidationModal(this.error_message_generic);
		}
	},
	methods: {
		showValidationModal(msg) {
			if (this.skip_current_banking_solution) {
				localStorage.setItem("skip_current_banking_solution", this.skip_current_banking_solution);
				this.skip_current_banking_solution = false;
				this.$bvModal.show("skip-banking-solution-modal");
			} else {
				this.error_message = msg;
				this.$refs["validation-modal"].show();
			}
		},
		hidevalidationModal() {
			if (localStorage.getItem('consumer_token') != null) {
				this.$router.push("/banklinking");
			} else {
				this.$router.push("/login");
			}
		},
		logAkoyaError(queryParams, codeExists) {
			let self = this;
			const strQueryParams = JSON.stringify(queryParams);
			var sessionID = (localStorage.getItem('consumer_token') != null) ? self.currentUser.user_id : self.sessionID;
			var selected_bank = JSON.parse(localStorage.getItem("selected_bank"));
			var request = {
				akoya_query_params: strQueryParams,
				code_exists: codeExists,
				action_type: self.action_type,
				sessionID: sessionID,
				bank_id: selected_bank.id,
          		batch_id: selected_bank.batch_id,
			};
			account_api
			.logAkoyaError(request)
			.then((response) => {
			})
			.catch(function (err) {
			});
		},
		revokeAkoyaToken() {
			let self = this;
			if (self.link_success) {
				self.hidevalidationModal();
			} else {
				var selected_bank = JSON.parse(localStorage.getItem("selected_bank"));
				var sessionID = (localStorage.getItem('consumer_token') != null) ? '' : self.sessionID;
				var request = {
					session_id: sessionID,
					type: self.action_type,
					akoya_provider_id: selected_bank.akoya_provider_id,
				};
				if (self.action_type == 'bank_change') {
					account_api
					.revokeUserAkoyaToken(request)
					.then((response) => {
						self.hidevalidationModal();
					})
					.catch(function (err) {
						self.hidevalidationModal();
					});
				} else {
					account_api
					.revokeAkoyaToken(request)
					.then((response) => {
						self.hidevalidationModal();
					})
					.catch(function (err) {
						self.hidevalidationModal();
					});
				}
			}
		},
		registration: function () {
			let self = this;
			this.isLoading = true;
			var selected_bank = JSON.parse(localStorage.getItem("selected_bank"));
			var request = {
				sessionId: this.sessionID,
				banking_solution: 'akoya',
				code: self.codeValue,
				bank_name: selected_bank.bank_name,
          		bank_id: selected_bank.id,
          		batch_id: selected_bank.batch_id,
			};
			api
				.registerUser(request)
				.then((response) => {
					if (response.code == 200) {
						this.isLoading = false;
						localStorage.setItem("user_id", response.data.user_id);
						localStorage.setItem("enrollment", true);
						self.link_success = true;
						if (
							typeof response.data.global_radar != "undefined" &&
							response.data.global_radar == 1
						) {
							self.showValidationModal("Registration Complete. You may now login.");
						} else {
							self.$router.push("/successbanklisting");
						}
					}
				})
				.catch((err) => {
					self.isLoading = false;
					const error_message = err?.response?.data?.data?.all_accounts_skipped === 1 
										? self.error_message_only_checking_or_savings 
										: self.error_message_generic;
					self.skip_current_banking_solution = err?.response?.data?.data?.skip_current_banking_solution === 1 
														? true 
														: false;
					self.showValidationModal(error_message);
				});
		},
		redirectToSuccess() {
			let self = this;
			self.isLoading = true;
			var selected_bank = JSON.parse(localStorage.getItem("selected_bank"));
			var request = {
				session_id: self.sessionID,
				banking_solution: 'akoya',
				code: self.codeValue,
				bank_name: selected_bank.bank_name,
          		bank_id: selected_bank.id,
          		batch_id: selected_bank.batch_id,
			};
			onboarding_api
				.Finalonboarding(request)
				.then((response) => {
				self.isLoading = true;
				if (response.code == 200) {
					self.link_success = true;
					localStorage.setItem("user_id", response.data.user_id);
					self.$router.push("/successbanklisting");
				}
				})
				.catch((err) => {
					self.isLoading = false;
					const error_message = err?.response?.data?.data?.all_accounts_skipped === 1 
										? self.error_message_only_checking_or_savings 
										: self.error_message_generic;
					self.skip_current_banking_solution = err?.response?.data?.data?.skip_current_banking_solution === 1 
														? true 
														: false;
					self.showValidationModal(error_message);
				});
		},
		akoyaBankUpdate() {
			let self = this;
			self.updateFinicityBankDetails();
		},
		// Bank Update
		updateFinicityBankDetails() {
			var self = this;
			self.isLoading = true;
			var selected_bank = JSON.parse(localStorage.getItem("selected_bank"));
			var request = {
				bank_name: selected_bank.bank_name,
				banking_solution: 'akoya',
				bank_id: selected_bank.id,
				batch_id: selected_bank.batch_id,
				code: self.codeValue,
				is_finicity_delink: localStorage.getItem('delink_finicity_banking_solution') == 'true' ? true : false,
			};
			account_api
				.updateBank(request)
				.then((response) => {
					this.show = "update_success";
					const consumer_login_response = JSON.parse(localStorage.getItem("consumer_login_response"));
					consumer_login_response.account_no = response.data.account_no;
					localStorage.setItem("consumer_login_response", JSON.stringify(consumer_login_response));
					self.link_success = true;
					localStorage.removeItem('delink_finicity_banking_solution');
					self.getUserdetails();
				})
				.catch(function (err) {
					self.isLoading = false;
					const error_message = err?.response?.data?.data?.all_accounts_skipped === 1 
										? self.error_message_only_checking_or_savings 
										: self.error_message_generic;
					self.skip_current_banking_solution = err?.response?.data?.data?.skip_current_banking_solution === 1 
														? true 
														: false;
					self.showValidationModal(error_message);
				});
		},
		//stores the bank details into canpay end
		storeBankDetails() {
			var self = this;
			this.isLoading = true;
			var selected_bank = JSON.parse(localStorage.getItem("selected_bank"));
			let request = {
				bank_name: selected_bank.bank_name,
				banking_solution: 'akoya',
				bank_id: selected_bank.id,
				batch_id: selected_bank.batch_id,
				code: self.codeValue,
			};
			account_api
				.directLinkBank(request)
				.then((response) => {
					this.show = "update_success";
					localStorage.setItem(
						"consumer_login_response",
						JSON.stringify(response.data)
					);
					self.getUserdetails();
				})
				.catch(function (err) {
					self.isLoading = false;
					self.showValidationModal(err.response.data.message);
				});
		},
		getUserdetails(){
			var self = this;
			account_api
			.getUserdetails()
			.then(function (response) {
				if (response.code == 200) {
				localStorage.setItem(
					"consumer_login_response",
					JSON.stringify(response.data)
				);
				self.currentUser = localStorage.getItem("consumer_login_response")
				? JSON.parse(localStorage.getItem("consumer_login_response"))
				: null;
				self.$router.push("/successbanklisting");
				}
			})
			.catch(function (error) {
			});
		},
	}
}
</script>
<style scoped>
.bg-findbank-success{
    background-color: #fff;
    border-radius: 10px;
}
.find-bank-success-button{
    background-color: #000;
    color:#fff;
    border-radius: 10px;
    border: none;
    padding: 15px 80px;
}
</style>
